import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { uiConstants } from './../../../utils/ui-constants'
import { copyTextToClipboard } from './../../../utils/helper-functions'
import { breakpoints } from './../../../utils/theme'
import Layout from './Layout'

const Footer = (props) => {

    const [displayMessage, setDisplayMessage] = useState(false);
    const [language, setLanguage] = useState(
        props.location.pathname.split("/")[1]
    );

    useEffect(() => {
        setTimeout(() => {
            setDisplayMessage(false);
        }, 2000)
    }, [displayMessage])

    return (
        <StyledFooter id="footer" width="full-width">
            {props.showDecoration && (
                <img className="wave-top" src={'/images/footer/wave-top.png'} />
            )}
            <div className="main-width footer-container">
                <div className="footer-row">
                    <a className="logo" href={`/${language}`}>
                        <img src="/images/footer/logo.svg" />
                    </a>
                    <div className="navigation">
                        <nav>
                            <ul>
                                {uiConstants[language].Footer.footerNavigation.map((link, key) => (
                                    <li key={key}>
                                        <a href={`#${link.targetId}`}>
                                            {link.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div 
                        className="email" 
                        onClick={() => {
                            copyTextToClipboard('hello@bitcoin-alliance.org');
                            setDisplayMessage(true);
                        }}
                    >
                        <span href="mailto:hello@bitcoin-alliance.org">
                            <img src={'/images/footer/email.svg'} />
                            hello@bitcoin-alliance.org
                        </span>
                        <div className={`tooltip ${displayMessage ? 'clicked' : ''}`}>
                            <span>{uiConstants[language].EmailCopy.copiedLabel}</span>
                        </div>
                    </div>
                </div>
                <div className="footer-row">
                    <a className="top" target="_blank" href={`/downloads/Bitcoin Bankathon – Participant Terms and Conditions.pdf`}>
                        {uiConstants[language].Footer.participationTermsLink.label}
                    </a>
                </div>
            </div>
        </StyledFooter>
    )
}

Footer.defaultProps = {
    showDecoration: true
}

const StyledFooter = styled(Layout)`
    background: #006AFF;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 148px;
    padding-top: 24px;
    @media screen and (max-width: ${breakpoints.sl}) {
        height: unset;
    }
    .footer-container {
        width: 100%;
    }
    .wave-top {
        width: 40%;
        position: absolute;
        top: -60px;
        @media screen and (max-width: ${breakpoints.md}) {
            width: 50%;
        }
    }
    .footer-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        &:last-child {
            justify-content: center;
        }
        a {
            color: rgba(255, 255, 255, 1);
            font-weight: 500;
            font-size: 12px;
        }
        .navigation {
            ul {
                display: flex;
                li {
                    margin: 0 12px;
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
        .email {
            cursor: pointer;
            position: relative;
            span {
                display: flex;
                align-items: center;
                color: white;
                font-weight: 500;
                font-size: 12px;
                img {
                    margin-right: 8px;
                }
            }
            .tooltip {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 100%;
                background: white;
                text-align: center;
                width: 188px;
                height: 48px;
                border-radius: 2px;
                filter: drop-shadow(
                    0px 3px 6px  rgba(1, 0, 45, 0.08)
                );
                opacity: 0;
                pointer-events: none;
                transition: ease-in-out 0.25s all;
                z-index: 100;
                &.clicked {
                    opacity: 1;
                    bottom: calc(100% + 16px);
                }
                &:after {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background: white;
                    transform: rotate(45deg);
                    content: '';
                    bottom: -5px;
                }
                span {
                    color: #394C6C;
                    font-weight: 300;
                    font-size: 12px;
                    &.copy-label {
                        font-size: 10px;
                        margin-top: 4px;
                    }
                }
            }
        }
        .top {
            font-size: 10px;
        }
    }
    @media screen and (max-width: ${breakpoints.sl}) {
        .footer-row {
            height: unset;
            &:first-child {
                flex-wrap: wrap;
                .logo {
                    width: 30%;
                }
                .navigation {
                    width: 70%;
                    nav {
                        ul {
                            li {
                                &:first-child {
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                }
                .email {
                    margin: 24px auto;
                }
            }
            &:last-child {
                padding: 12px 0 32px;
            }
        }
    }
    @media screen and (max-width: ${breakpoints.md}) {
        padding-bottom: 48px;
        .footer-row {
            flex-direction: column;
            height: unset;
            &:first-child {
                flex-wrap: unset;
                .logo {
                    width: unset;
                }
                .navigation {
                    width: unset;
                }
            }
            &:last-child {
                padding: 0px 0 12px;
            }
            .logo {
                margin-bottom: 40px;
            }
            .navigation {
                margin-bottom: 52px;
                ul {
                    li {
                        margin: 0 6px;
                    }
                }
            }
            .email {
                width: 100%;
                display: flex;
                justify-content: center;
                padding-bottom: 20px;
                margin-bottom: 24px;
                border-bottom: 1px solid rgba(49, 131, 255, 1);
            }
        }
    }
    @media screen and (max-width: ${breakpoints.s}) {
        .footer-row {
            .navigation {
                width: 100%;
                ul {
                    width: inherit;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    li {
                        margin: 0 8px 16px !important;
                    }
                }
            }
        }
    }
`;

export default props => (
    <Location>
        {locationProps => <Footer {...locationProps} {...props} />}
    </Location>
)
