import React, {useEffect, useState} from "react"
import styled from 'styled-components';
import { Location } from '@reach/router'
import { uiConstants } from './../../../utils/ui-constants'
import Button from "../common/Button";
import { breakpoints } from './../../../utils/theme'
import Layout from './../common/Layout'

const PlaybookList = (props) => {
    const [language, setLanguage] = useState(
        props.location.pathname.split("/")[1]
    );

    const ItemHeader = (props) => (
        <div className="list-item-header">
            <span className="number">{props.index}•</span>
            <span className="title">{props.title}</span>
        </div>
    )

    return (
        <StyledPlaybookList width="main-width" data-aos="fade-up">
            <ul className="playbook-list">
                {uiConstants[language].Playbook.PlaybookList.map((listItem, index) => {
                    if (index == 0) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    <div className="register-steps">
                                        <div className="register-step">
                                            <div className="register-step-header">
                                                <img src="/images/playbook/Register-Card-1.svg" />
                                            </div>
                                            <div className="register-step-content">
                                                <p>
                                                    Go to bitcoin-bankathon.devpost.com and click on “My Projects” in the navigation menu and then click “Create Project”
                                                </p>
                                            </div>
                                        </div>
                                        <div className="register-step">
                                            <div className="register-step-header">
                                                <img src="/images/playbook/Register-Card-2.svg" />
                                            </div>
                                            <div className="register-step-content">
                                                <p>
                                                    After naming your project, scroll down and enter your teammates emails to invite them to your team. 
                                                </p>
                                            </div>
                                        </div>
                                        <div className="register-step">
                                            <div className="register-step-header">
                                                <img src="/images/playbook/Register-Card-3.svg" />
                                            </div>
                                            <div className="register-step-content">
                                                <p>
                                                    On the Project dashboard, you can describe what you're building, which challenge your solution is addressing, and what technology you’ll be using.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    }

                    if (index == 1) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    <div className="button-banner">
                                        <div className="banner-text">
                                            {listItem.bannerText}
                                        </div>
                                        <Button 
                                            to={listItem.button.href}
                                            external
                                            label={
                                                <>
                                                    {listItem.button.label}
                                                    <img src="/images/playbook/Discord.svg" />
                                                </>} 
                                            type={'white'}
                                        />
                                    </div>
                                    <div className="countdown-cta-container">
                                        <Button external to={'https://bitcoin-bankathon.devpost.com'} className="display-mobile" type={'primary'} label={
                                                <>
                                                    {listItem.button.label}
                                                    <img src="/images/playbook/Discord.svg" />
                                                </>} />
                                    </div>
                                </div>
                            </li>
                        )
                    }

                    if (index == 2) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    <p className="subheader">{listItem.subheader}</p>
                                    <div className="large-video-container">
                                        <div className="header-video">
                                            <div className="video-container">
                                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Y8EvGLLWjPQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                            <div className="video-description">
                                                <h3>
                                                    {listItem.mainContent.title}
                                                </h3>
                                                <p>
                                                    {listItem.mainContent.body}
                                                </p>
                                            </div>
                                            <div className="separator">
                                                <div className="left-plus plus" />
                                                <div className="right-plus plus" />
                                                <div className="line" />
                                            </div>
                                        </div>
                                        <div className="link-groups">
                                            <ul className="link-groups-list">
                                                {listItem.mainContent.linkGroups.map((linkGroup, index) => (
                                                    <li className="link-group">
                                                        <h4>{linkGroup.title}</h4>
                                                        <div className={`link-group-links links-${linkGroup.links.length}`}>
                                                            <ul>
                                                                {linkGroup.links.map((link, index) => (
                                                                    <a href={link.href} target="_blank">
                                                                        <li>
                                                                            <img src="/images/playbook/links-icon.svg" className="link-icon"/>
                                                                            <span>{link.content}</span>
                                                                        </li>
                                                                    </a>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="large-video-container">
                                        <div className="header-video">
                                            <div className="video-container">
                                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?list=PLSyELPaIli1nTanw98_jtJEqCxXnYf07s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                            <div className="video-description">
                                                <h3>
                                                    {listItem.rskTutorial.title}
                                                </h3>
                                                <p>
                                                    {listItem.rskTutorial.body}
                                                </p>
                                            </div>
                                            <div className="separator">
                                                <div className="left-plus plus" />
                                                <div className="right-plus plus" />
                                                <div className="line" />
                                            </div>
                                        </div>
                                        <div className="link-groups">
                                            <ul className="link-groups-list">
                                                {listItem.rskTutorial.linkGroups.map((linkGroup, index) => (
                                                    <li className="link-group">
                                                        <h4>{linkGroup.title}</h4>
                                                        <div className={`link-group-links links-${linkGroup.links.length}`}>
                                                            <ul>
                                                                {linkGroup.links.map((link, index) => (
                                                                    <a href={link.href} target="_blank">
                                                                        <li>
                                                                            <img src="/images/playbook/links-icon.svg" className="link-icon"/>
                                                                            <span>{link.content}</span>
                                                                        </li>
                                                                    </a>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="large-video-container">
                                        <div className="header-video">
                                            <div className="video-container">
                                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/pQ04frLBHds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                            <div className="video-description">
                                                <h3>
                                                    {listItem.mentorSessions.title}
                                                </h3>
                                                <p>
                                                    {listItem.mentorSessions.body}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="large-video-container video-right">
                                        <div className="header-video">
                                            <div className="video-container">
                                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/pQ04frLBHds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                            <div className="video-description">
                                                <span className="preheader">
                                                    {listItem.qredo.preheader}
                                                </span>
                                                <h3>
                                                    {listItem.qredo.title}
                                                </h3>
                                            </div>
                                            <div className="separator">
                                                <div className="left-plus plus" />
                                                <div className="right-plus plus" />
                                                <div className="line" />
                                            </div>
                                        </div>
                                        <div className="video-container-body">
                                            <p>
                                                {listItem.qredo.detailsBody}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="large-video-container video-right">
                                        <div className="header-video">
                                            <div className="video-container">
                                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/MJftC_QVdns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                            <div className="video-description">
                                                <span className="preheader">
                                                    {listItem.solidity.preheader}
                                                </span>
                                                <h3>
                                                    {listItem.solidity.title}
                                                </h3>
                                            </div>
                                            <div className="separator">
                                                <div className="left-plus plus" />
                                                <div className="right-plus plus" />
                                                <div className="line" />
                                            </div>
                                        </div>
                                        <div className="video-container-body">
                                            <p>
                                                {listItem.solidity.detailsBody.map((text, index) => (
                                                    <p key={index}>{text}</p>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                    if (index == 3) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    {listItem.content}
                                </div>
                            </li>
                        )
                    }
                    if (index == 4) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    {listItem.content}
                                </div>
                            </li>
                        )
                    }
                    if (index == 5) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    <p className="subheader">{listItem.subheader}</p>
                                    <div className="button-banner">
                                        <div className="banner-text">
                                            <span>
                                                {listItem.bannerText}
                                            </span>
                                        </div>
                                        <Button 
                                            to={listItem.button.href}
                                            label={
                                                <>
                                                    {listItem.button.label}
                                                    <img src="/images/playbook/Eventbrite.svg" />
                                                </>}
                                            type={'white'}
                                        />
                                    </div>
                                    <div className="countdown-cta-container">
                                        <Button external to={'https://bitcoin-bankathon.devpost.com'} className="display-mobile" type={'primary'} label={
                                            <>
                                                {listItem.button.label}
                                                <img src="/images/playbook/Eventbrite.svg" />
                                            </>} />
                                    </div>
                                </div>
                            </li>
                        )
                    }
                    if (index == 6) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    <ul className="list">
                                        {listItem.content}
                                    </ul>
                                </div>
                            </li>
                        )
                    }
                    if (index == 7) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    <ul className="criteria-list list">
                                        {listItem.criteriaList.map((criteria, index) => (
                                            <li key={index}>
                                                {criteria}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </li>
                        )
                    }
                    if (index == 8) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    {listItem.content}
                                </div>
                            </li>
                        )
                    }
                    if (index == 9) {
                        return (
                            <li key={index} className={`playbook-item playbook-item-${index + 1}`} data-aos="fade-up">
                                <ItemHeader 
                                    index={index + 1} 
                                    title={listItem.title} 
                                />
                                <div className="item-content">
                                    {listItem.content}
                                </div>
                            </li>
                        )
                    }
                })}
            </ul>
            <div className="main-width cta-container" data-aos="fade-up">
                <p className="cta-text">{uiConstants[language].Playbook.CTAText}</p>
            </div>
            <div className="main-width opening-ceremony" data-aos="fade-up">
                <div className="video-header">
                    <h2 className="title">{uiConstants[language].OpeningCeremony.title}</h2>
                    <div className="video-container">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zkjhYAxyxIQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="body">
                    <h2 className="title">{uiConstants[language].OpeningCeremony.title}</h2>
                    <p>{uiConstants[language].OpeningCeremony.detail}</p>
                    <div className="links-group">
                        <h3 className="title">{uiConstants[language].OpeningCeremony.linkGroup.title}</h3>
                        <ul>
                            {uiConstants[language].OpeningCeremony.linkGroup.links.map((link, index) => (
                                <a href={link.href}>
                                    <li>
                                        <img src="/images/playbook/links-icon.svg"/>
                                        <span>{link.title}</span>
                                    </li>
                                </a>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="main-width cta-container" data-aos="fade-up">
                <p className="cta-text">{uiConstants[language].Playbook.CTADiscord}</p>
            </div>
        </StyledPlaybookList>
    )
}

const StyledPlaybookList = styled(Layout)`
    padding: 0 100px !important;
    @media screen and (max-width: ${breakpoints.l}) {
        padding: 0 7.5% !important;
    }
    @media screen and (max-width: ${breakpoints.sl}) {
        width: 740px !important;
    }
    @media screen and (max-width: ${breakpoints.md}) {
        /* padding: 0 7.5% !important; */
        width: 100% !important;
    }
    .playbook-list {
        .playbook-item {
            margin-bottom: 96px;
            .list-item-header {
                display: flex;
                align-items: center;
                margin-bottom: 64px;
                @media screen and (max-width: ${breakpoints.sl}) {
                    margin-bottom: 40px;
                }
                .number {
                    color: white;
                    font-size: 96px;
                    font-weight: 900;
                    -webkit-text-stroke: 1px rgba(0, 106, 255, 1);
                    filter: drop-shadow(-2px 2px 0 rgba(0, 106, 255, 1));
                    @media screen and (max-width: ${breakpoints.sl}) {
                        font-size: 45px;
                    }
                }
                .title {
                    font-size: 45px;
                    line-height: 49px;
                    font-weight: 800;
                    color: rgba(28, 51, 84, 1);
                    margin-left: 32px;
                    @media screen and (max-width: ${breakpoints.sl}) {
                        line-height: 36px;
                        font-size: 28px;
                    }
                }
            }
            .item-content {
                .subheader {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 300;
                    @media screen and (max-width: ${breakpoints.sl}) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .list {
                    li {
                        margin-bottom: 16px;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 30px;
                        color: rgba(57, 76, 108, 1);
                        position: relative;
                        left: 34px;
                        @media screen and (max-width: ${breakpoints.sl}) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                        &:after {
                            content: '';
                            width: 24px;
                            height: 24px;
                            position: absolute;
                            background-image: url('/images/playbook/icon-plus.svg');
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            left: -32px;
                            top: calc(50% - (24px / 2));
                        }
                        span {
                            &.name {
                                color: rgba(0, 106, 255, 1);
                                margin-right: 4px;
                            }
                        }
                    }
                }
                p {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0em;
                    color: #394C6C;
                    margin-bottom: 16px;
                    @media screen and (max-width: ${breakpoints.sl}) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a, .hashtag {
                        color: #006AFF;
                    }
                    a {
                        text-decoration: underline;
                    }
                }
                .button-banner {
                    background: linear-gradient(264.37deg, #15FFD5 -4.93%, #006AFF 98.64%);
                    border-radius: 24px;
                    padding: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @media screen and (max-width: ${breakpoints.sl}) {
                        flex-direction: column;
                        padding: 24px;
                        border-radius: 8px;
                    }
                    .banner-text {
                        color: white;
                        width: 518px;
                        font-size: 20px;
                        line-height: 30px;
                        font-weight: 400;
                        justify-content: center;
                        display: flex;
                        @media screen and (max-width: ${breakpoints.sl}) {
                            font-size: 14px;
                            line-height: 16px;
                            width: 100%;
                            margin-bottom: 0px;
                            width: unset;
                        }
                    }
                    .button {
                        /* width: 310px; */
                        height: 62px;
                        @media screen and (max-width: ${breakpoints.sl}) {
                            display: none;
                        }
                        a {
                            height: inherit;
                            .label {
                                font-size: 20px;
                                text-transform: initial;
                                letter-spacing: 0;
                                font-weight: 300;
                                display: flex;
                                align-items: center;
                                img {
                                    margin-left: 8px;
                                }
                                @media screen and (max-width: ${breakpoints.sl}) {
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
                .countdown-cta-container {
                    margin-top: 18px;
                    display: flex;
                    justify-content: center;
                    .button {
                        height: 36px;
                        a {
                            height: inherit;
                            .label {
                                font-size: 10px !important;
                                text-transform: initial;
                                letter-spacing: 0;
                                font-weight: 300;
                                display: flex;
                                align-items: center;
                                img {
                                    margin-left: 8px;
                                }
                                @media screen and (max-width: ${breakpoints.sl}) {
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                    .display-mobile {
                        display: none;
                        margin-bottom: 16px;
                        &:first-child {
                            margin-right: 28px;
                        }
                        @media screen and (max-width: ${breakpoints.sl}) {
                            display: unset;
                        }
                        @media screen and (max-width: ${breakpoints.md}) {
                            width: 197px;
                            &:first-child {
                                margin-right: 0;
                            }
                        }
                    }
                    @media screen and (max-width: ${breakpoints.md}) {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            &.playbook-item-1 {
                .item-content {
                    .register-steps {
                        display: flex;
                        justify-content: space-between;
                        @media screen and (max-width: ${breakpoints.sl}) {
                            flex-direction: column;
                            align-items: center;
                        }
                        .register-step {
                            width: 303px;
                            @media screen and (max-width: ${breakpoints.sl}) {
                                margin-bottom: 24px;
                            }
                            .register-step-header {
                                position: relative;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    left: 22px;
                                    top: calc(100% - 1px);
                                    width: 1px;
                                    height: 79px;
                                    background: #FFD600;
                                }
                            }
                            .register-step-content {
                                padding: 18px 22px 0 44px;
                                p {
                                    font-weight: 300;
                                    color: rgba(57, 76, 108, 1);
                                    font-size: 14px;
                                    line-height: 21px;
                                }
                            }
                        }
                    }
                }
            }
            &.playbook-item-3 {
                .item-content {
                    .large-video-container {
                        margin-top: 96px;
                        border: 1px solid rgba(255, 214, 0, 1);
                        display: flex;
                        flex-direction: column;
                        &.video-right {
                            .header-video {
                                flex-direction: row-reverse;
                                @media screen and (max-width: ${breakpoints.sl}) {
                                    flex-direction: column-reverse;
                                }
                                .video-description {
                                    margin: 0 24px 0 0;
                                }
                            }
                        }
                        .preheader {
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 2px;
                            margin-bottom: 16px;
                            display: inline-block;
                            color: rgba(0, 106, 255, 1);
                            @media screen and (max-width: ${breakpoints.sl}) {
                                font-size: 12px;
                                line-height: 18px;
                                margin-bottom: 8px;
                            }
                        }
                        .header-video {
                            padding: 24px 24px 32px;
                            display: flex;
                            position: relative;
                            @media screen and (max-width: ${breakpoints.sl}) {
                                flex-direction: column;
                                justify-content: center;
                            }
                            .video-description {
                                width: 297px;
                                margin-left: 24px;
                                padding-top: 24px;
                                @media screen and (max-width: ${breakpoints.sl}) {
                                    width: 100%;
                                    margin-left: 0;
                                    padding-top: 18px;
                                }
                                h3 {
                                    font-size: 32px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 48px;
                                    letter-spacing: 0.1em;
                                    text-transform: uppercase;
                                    color: rgba(28, 51, 84, 1);
                                    margin-bottom: 16px;
                                    @media screen and (max-width: ${breakpoints.sl}) {
                                        font-size: 20px;
                                        line-height: 30px;
                                    }
                                }
                                p {
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 30px;
                                    color: rgba(57, 76, 108, 1);
                                    @media screen and (max-width: ${breakpoints.sl}) {
                                        font-size: 16px;
                                        line-height: 24px;
                                    }
                                }
                            }
                            .video-container {
                                width: 604px;
                                height: 340px;
                                background: whitesmoke;
                                @media screen and (max-width: ${breakpoints.sl}) {
                                    width: 100%;
                                    height: 260px;
                                }
                                @media screen and (max-width: ${breakpoints.md}) {
                                    width: 100%;
                                    height: 190px;
                                    margin: 0 auto;
                                }
                            }
                            .separator {
                                bottom: 10px;
                                position: absolute;
                                width: 100%;
                                left: 0;
                                .plus {
                                    width: 24px;
                                    height: 24px;
                                    background-image: url('/images/playbook/icon-plus.svg');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                    position: absolute;
                                    @media screen and (max-width: ${breakpoints.sl}) {
                                        display: none;
                                    }
                                    &.left-plus {
                                        left: 24px;
                                    }
                                    &.right-plus {
                                        right: 24px;
                                    }
                                }
                                .line {
                                    height: 1px;
                                    width: calc(100% - (24px * 2));
                                    position: absolute;
                                    top: 12px;
                                    left: 58px;
                                    background: rgba(255, 214, 0, 1);
                                    @media screen and (max-width: ${breakpoints.sl}) {
                                        /* width: calc(100% - (18px * 2)); */
                                        left: 24px;
                                    }
                                }
                            }
                        }
                        .video-container-body {
                            padding: 24px 24px 32px;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            p {
                                width: 719px;
                                margin-bottom: 36px;
                                @media screen and (max-width: ${breakpoints.sl}) {
                                    width: 100%;
                                }
                            }
                        }
                        .link-groups {
                            padding: 52px 0;
                            min-width: 846px;
                            margin: 0 auto;
                            @media screen and (max-width: ${breakpoints.sl}) {
                                padding: 24px;
                                min-width: unset;
                            }
                            .link-groups-list {
                                .link-group {
                                    margin-bottom: 24px;
                                    h4 {
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 29px;
                                        letter-spacing: 2px;
                                        color: rgba(0, 83, 210, 1);
                                        margin-bottom: 24px;
                                        @media screen and (max-width: ${breakpoints.sl}) {
                                            margin-bottom: 12px;
                                        }
                                    }
                                    .link-group-links {
                                        width: 100%;
                                        &.links-1 {
                                            ul {
                                                a {
                                                    width: 50%;
                                                    li {
                                                        &::after,
                                                        &::before {
                                                            display: none !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        ul {
                                            display: flex;
                                            justify-content: space-between;
                                            width: 100%;
                                            @media screen and (max-width: ${breakpoints.sl}) {
                                                flex-direction: column;
                                            }
                                            a {
                                                width: inherit;
                                                width: 260px;
                                                @media screen and (max-width: ${breakpoints.sl}) {
                                                    margin-bottom: 16px;
                                                    width: 100%;
                                                }
                                                li {
                                                    &:after,
                                                    &:before {
                                                        position: absolute;
                                                        height: 100%;
                                                        width: 1px;
                                                        top: 0;
                                                        background: rgba(255, 214, 0, 1);
                                                        @media screen and (max-width: ${breakpoints.sl}) {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                                &:first-child {
                                                    li {
                                                        &:after {
                                                            content: '';
                                                            right: -18px;
                                                        }
                                                    }
                                                }
                                                &:last-child {
                                                    li {
                                                        &:after {
                                                            content: '';
                                                            left: -18px;
                                                        }
                                                    }
                                                }
                                                li {
                                                    height: 120px;
                                                    border-radius: 4px;
                                                    padding: 18px 0 0 16px;
                                                    display: flex;
                                                    flex-direction: column;
                                                    background: linear-gradient(215.46deg, #FFF6E1 1.83%, rgba(255, 248, 234, 0.890625) 1.84%, #FFFDF8 100%);
                                                    transition: ease-in-out 0.25s box-shadow;
                                                    position: relative;
                                                    &:hover {
                                                        box-shadow: 0 4px 16px rgba(0, 0 , 0, 0.1)
                                                    }
                                                    span {
                                                        font-size: 14px;
                                                        font-style: normal;
                                                        font-weight: 300;
                                                        line-height: 21px;
                                                        color: rgba(57, 76, 108, 1);
                                                    }
                                                    img {
                                                        width: 30px;
                                                        margin-bottom: 16px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.playbook-item-4 {
                .item-content {
                    .list {
                        margin-top: 32px;
                        li {
                            &:after {
                                background-image: url('/images/playbook/icon-arrow.svg');
                            }
                        }
                    }
                }
            }
        }
    }
    .cta-container {
        margin: 176px auto;
        .cta-text {
            text-align: center;
            font-weight: 500;
            font-size: 43px;
            line-height: 65px;
            background: -webkit-linear-gradient(180deg, #006AFF -4.93%, #15FFD5 98.64%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
            display: block;
            /* margin: 0 auto; */
            width: fit-content;
            margin: 0 auto !important;
            &:before,
            &:after {
                top: -20px;
                position: absolute;
                content: '';
                width: 32px;
                height: 32px;
                background-image: url('/images/breakdown/arrow.png');
            }
            &:before {
                left: -80px;
            }
            &:after {
                right: -80px;
                transform: rotate(90deg);
            }
            a {
                border-bottom: 1px solid #14ffd4;
            }
            @media screen and (max-width: ${breakpoints.sl}) {
                margin: 0;
                font-size: 28px;
                line-height: 42px;
                &:before,
                &:after {
                    width: 22px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    top: -8px;
                }
                &:before {
                    left: -32px;
                }
                &:after {
                    right: -32px;
                }
            }
            @media screen and (max-width: ${breakpoints.s}) {
                margin: 0 auto;
            }
        }
        @media screen and (max-width: ${breakpoints.sl}) {
            margin-top: 80px;
        }
    }
    .opening-ceremony {
        padding: 32px;
        border: 1px solid rgba(0, 106, 255, 1);
        @media screen and (max-width: ${breakpoints.sl}) {
            padding: 18px;
        }
        h2 {
            color: white;
            font-size: 56px;
            font-weight: 900;
            margin: 0 auto 24px;
            text-align: center;
            -webkit-text-stroke: 1px rgba(0, 106, 255, 1);
            filter: drop-shadow(-2px 2px 0 rgba(0, 106, 255, 1));
        }
        .video-header {
            padding-bottom: 24px;
            h2 {
                display: none;
                font-size: 28px;
                line-height: 36px;
                @media screen and (max-width: ${breakpoints.sl}) {
                    /* display: unset; */
                    display: block;
                }
            }
            .video-container {
                width: 908px;
                height: 508px;
                background: whitesmoke;
                @media screen and (max-width: ${breakpoints.sl}) {
                    width: 529px;
                    height: 209px;
                    margin: 0 auto;
                }
                @media screen and (max-width: ${breakpoints.md}) {
                    width: 100%;
                    height: 42vw;
                }
            }
        }
        .body {
            h2 {
                margin: 64px auto 72px;
                @media screen and (max-width: ${breakpoints.sl}) {
                    display: none;
                }
            }
        }
        
        p {
            font-size: 28px;
            font-weight: 400;
            line-height: 42px;
            letter-spacing: 0.02em;
            margin-bottom: 32px;
            @media screen and (max-width: ${breakpoints.sl}) {
                margin-bottom: 24px;
                display: inline-block;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .links-group {
            padding: 0 28px;
            @media screen and (max-width: ${breakpoints.sl}) {
                padding: 0;
            }
            h3 {
                font-size: 18px;
                font-weight: 600;
                line-height: 29px;
                letter-spacing: 2px;
                color: rgba(0, 106, 255, 1);
                margin-bottom: 20px;
            }
            ul {
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: ${breakpoints.md}) {
                    flex-direction: column;
                }
                a {
                    width: 404px;
                    @media screen and (max-width: ${breakpoints.sl}) {
                        width: calc(50% - 12px);
                    }
                    @media screen and (max-width: ${breakpoints.md}) {
                        width: 100%;
                    }
                    li {
                        display: flex;
                        flex-direction: column;
                        padding: 18px 16px;
                        background: linear-gradient(215.46deg, #DEFDFF 1.83%, #DEFDFF 1.84%, #E4EFFF 100%);
                        border-radius: 4px;
                        height: 120px;
                        transition: ease-in-out 0.25s box-shadow;
                        width: 100%;
                        @media screen and (max-width: ${breakpoints.md}) {
                            margin-bottom: 18px;
                        }
                        &:hover {
                            box-shadow: 0 4px 16px rgba(0, 0 , 0, 0.1)
                        }
                        img {
                            width: 32px;
                            margin-bottom: 16px;
                        }
                        span {
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 21px;
                            color: rgba(57, 76, 108, 1);
                        }
                    }
                }
            }
        }
    }
`;

export default props => (
    <Location>
        {locationProps => <PlaybookList {...locationProps} {...props} />}
    </Location>
)