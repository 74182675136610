import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Root from './../components/common/Root'
import Header from './../components/common/Header'
import PlaybookList from './../components/page-components/PlaybookList'
import PlaybookHero from './../components/page-components/PlaybookHero'
import Footer from './../components/common/Footer'

export default function Playbook({ location }) {
  const [emailSubmitted, setEmailSubmitted] = useState(location.search == '?thanks');
  return (
    <Root>
      <Helmet>
        <title>Bitcoin Alliance - Playbook</title>
        <meta name="description" content="Bitcoin Alliance" />
        <script type="text/javascript" async src="https://www.googletagmanager.com/gtag/js?id=G-WMJ3Y8WYJM"></script>
        {/*  */}
        <meta property="og:title" content="Bitcoin Alliance" />
        <meta property="og:image" content="https://i.ibb.co/tBTnFMf/Website-Thumbnail-Image-1.png" />
        <meta property="og:description" content="Banco Hipotecario invita a todos los desarroladores, diseñadores, emprendedores y aquellos con ideas increíbles al Bitcoin Bankathon:  Una competición destinada a crear innovadoras soluciones bancarias  basadas en Bitcoin, que faciliten el acceso a servicios financieros mejorados para los ciudadanos salvadoreños." />
        <meta property="og:url" content="https://bitcoin-alliance.org/" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="626" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header hero={
        <PlaybookHero />
        } 
      />
      <main>
        <PlaybookList />
      </main>
      <Footer showDecoration={false}/>
    </Root>
  )
}
