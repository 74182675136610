import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import { uiConstants } from './../../../utils/ui-constants'
import { breakpoints } from './../../../utils/theme'
import Layout from './../common/Layout'
import HomepageHeroGraphicMobile from './../../images/hero-graphic-mobile.svg'
import WavesBottom from './../../images/waves.gif'

const HomepageHero = (props) => { 
    const [language, setLanguage] = useState(
        props.location.pathname.split("/")[1]
    );   
    return (
        <StyledHomepageHero>
            <Layout width="main-width" data-aos="fade-up">
                <div className="hero-content" data-aos="fade-up">
                    <h1>{uiConstants[language].Playbook.Hero.title}</h1>
                    <p className="sub-heading">
                        {uiConstants[language].Playbook.Hero.subHeader}
                    </p>
                </div>
            </Layout>
        </StyledHomepageHero>
    )
}

const StyledHomepageHero = styled(Layout)`
    position: relative;
    padding: 40px 0 0;
    margin-bottom: 120px;
    .hero-content {
        position: relative;
        z-index: 1;
        max-width: 971px;
        margin: 0 auto;
        h1 {
            font-size: 67px;
            line-height: 67px;
            font-weight: 900;
            color: black;
            max-width: 982px;
            color: rgba(28, 51, 84, 1);
        }
        .sub-heading {
            max-width: 680px;
            margin-top: 24px;
            font-size: 28px;
            font-weight: 500;
            color: gray;
            line-height: 42px;
            color: rgba(28, 51, 84, 1);
        }
    }
    @media screen and (max-width: ${breakpoints.xl}) {
        padding-top: 48px;
        .hero-content {
            padding: 0px;
        }
    }
    @media screen and (max-width: ${breakpoints.l}) {
        padding-top: 26px;
    }
    @media screen and (max-width: ${breakpoints.sl}) {
        padding-top: 20px;
        .hero-content {
            h1 {
                font-size: 67px;
                line-height: 75px;
            }
        }
    }
    @media screen and (max-width: ${breakpoints.md}) {
        .hero-content {
            padding: 0px;
            h1 {
                font-size: 63px;
            }
            .sub-heading {
                margin-top: 16px;
                max-width: 470px;
            }
        }
    }
    @media screen and (max-width: 1259px) {
        .hero-content {
            .sub-heading {
                font-size: 16px;
                margin-top: 16px;
                line-height: 24px;
            }
        }
    }
    @media screen and (max-width: ${breakpoints.s}) {
        padding: 20px 0 0;
        .hero-content {
            h1 {
                font-size: 39px;
                line-height: 47px;
            }
            .sub-heading {
                max-width: 470px;
            }
        }
    }
    @media screen and (max-width: ${breakpoints.xs}) {
       .hero-content {
            .sub-heading {
                span {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
`;

export default props => (
    <Location>
        {locationProps => <HomepageHero {...locationProps} {...props} />}
    </Location>
)
